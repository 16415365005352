<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>ENTRADAS A ALMACEN</h4>
        <app-table-registers
          ref="tr"
          :getList="getList"
          @btnNew="btnNewHandler"
        >
          <!-- <template slot="afterNewBtn">
            <SelectStore v-model="store_id"></SelectStore>
          </template> -->
          <template slot="table">
            <thead>
              <tr>
                <th>ID</th>
                <th></th>
                <th>FECHA</th>
                <th>CANTIDAD</th>
                <th class="text-right">VALOR</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <app-tr-loading v-show="loading" colspan="7"></app-tr-loading>
              <tr v-for="l in list" :key="l.id">
                <td>{{ l.id }}</td>
                <td>{{ l.created_at | date }}</td>
                <td>
                  <TypeBadge :typeId="l.type_id"></TypeBadge>
                </td>
                <td>{{ l.total_quantity }}</td>
                <td class="text-nowrap text-right">{{ l.value | money }}</td>
                <td>
                  <div class="btn-group">
                    <!-- :to="'/logistic/inputs/' + l.id" -->
                    <router-link
                      :to="{
                        name: 'logistic-input-show',
                        params: { inputId: l.id }
                      }"
                      class="btn btn-light btn-sm"
                      tag="a"
                      title="Editar/ver"
                    >
                      <i class="fa fa-edit"></i>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="elModalCreate" :lg="true">
      <Form ref="elFormCreate" @submitted="afterSaveHandler($event)"></Form>
    </app-modal-basic>
  </div>
</template>
<script>
import { LogisticService } from "../service";
import Form from "./FormCreate.vue";
import { mapState } from "vuex";
import TypeBadge from "./TypeBadge.vue";

export default {
  components: {
    Form,
    TypeBadge
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: [],
    store_id: null,
    loading: false
  }),

  computed: {
    ...mapState({
      storeId: (s) => s.user.store_id
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    afterSaveHandler(input) {
      this.$router.push({
        name: "logistic-input-show",
        params: {
          inputId: input.id
        }
      });
    },
    btnNewHandler() {
      this.$refs.elModalCreate.show();
      this.$refs.elFormCreate.reset({
        store_id: this.storeId
      });
    },
    getList({ page, search }) {
      this.loading = true;
      this.list = [];
      return new Promise((rsv) => {
        LogisticService.getInputs({
          page,
          search,
          store_id: this.storeId
        }).then((res) => {
          this.loading = false;
          this.list = res.data;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style scoped></style>
