<template>
  <span class="badge badge-success">
    <i
      class="fas"
      :class="{
        'fa-sign-in-alt': typeId == 1,
        'fa-clipboard-list': typeId == 2,
        'fa-clipboard-check': typeId == 3
      }"
    ></i>
  </span>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    typeId: {
      default: 1
    }
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
